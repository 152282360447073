import axios from "axios";

const http = () => {
  let instance = axios.create({
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://api.aerocoatings.com/v1/"
        : //: "http://192.168.86.36:3000/v1",
          //"http://intranetv2-dev/api/",
          "https://api.aerocoatings.com/v1/",
    headers: {
      "Content-type": "application/json",
    },
    timeout: 5000,
  });

  return instance;
};

export default http();
