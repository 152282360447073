import http from "./http-common";

export const getCapabilities = ({ queryKey }) => {
  console.log('queryKey', queryKey)
  const search = queryKey.length > 1 ? queryKey[1].partNumber : null;
 

  let url = "/capability/search";
  if (search) 
  console.log('queryKey',search)
  url += `?partNumbers=${search}`;
  console.log('queryKey', url)
  return http.get(url);
};
