import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../public/aciLogoBlue.png";
import s from "./Header.module.css";
import { color } from "framer-motion";

const drawerWidth = 240;
const navItems = ["HOME", "DOCUMENTS", "SEARCH REPAIRS"];

export default function Header() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box style={{backgroudColor: "rgb(25, 90, 146)"}} onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menu
      </Typography>
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton  sx={{ textAlign: "center" }}>
              <a className={s.link} href={"#" + item}>
                    {item}
                  </a>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <CssBaseline />
      <AppBar component="nav" style={{ backgroundColor: "white" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <img src={logo} className="App-logo" alt="logo" />
          </Box>
          <IconButton 
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon style={{backgroundColor: "rgb(25, 90, 146)"}} />
          </IconButton>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ul
              style={{
                color: "#0076BD",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              {navItems.map((item, index) => (
                <li key={index} style={{ display: "inline", marginRight: 20 }}>
                  <a className={s.link} href={"#" + item}>
                    {item}
                  </a>
                </li>
              ))}
              <li style={{ display: "inline", marginRight: 20 }}>
                <a
                  className={s.link}
                  href="https://www.aerocoatings.com/customerconsole/Login.asp"
                  target="_blank"
                >
                  CUSTOMER CONSOLE
                </a>
              </li>
            </ul>
          </Box>
          <Box></Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
