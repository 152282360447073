import * as React from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import s from "./Marketing.module.css";

function Marketing() {
  return (
    <div>
      <div id="Marketing" style={{ backgroundColor: "#333", color: "white", height: 580 }}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 2 }}
          style={{ padding: 0, margin: 0 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              textAlign: "left",
              padding: 10,
              paddingBottom: 5,
              margin: 0,
            }}
          >
            <center>
<           iframe id="vp146Mex" title="Video Player" width="700" height="500" frameborder="0"
            src="https://aci-website.s3.us-east-2.amazonaws.com/Images/ACIVideo.mp4" allowfullscreen>
            </iframe></center>
            
          </Grid>
          
        </Grid>
      </div>
    </div>
  );
}

export default Marketing;
