import React, { useState } from "react";
import s from "./Carousel.module.css";
import { images } from "../helpers/CarouselData.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button } from "@mui/material";

const Carousel = () => {
  const [style] = useState(s.carouselInner);
  let [currImg, setCurrImg] = useState(0);

  //setTimeout(() => imgAnimation(), 15000);

  /*function imgAnimation(){
    currImg > -1 && currImg !== 3 && setCurrImg(currImg + 1);
    
}*/

  const changeStyle = () => {
    currImg !== 0 && setCurrImg(currImg - 1);
  };

  if (currImg === 3) {
    currImg = 0;
  }

  return (
    <div className={s.carousel}>
        <div
        className={s.carouselInner}
        style={{ backgroundImage: `url(${images[currImg].img})` }}
      >
        <div id="case" className={style}>
          <div className={s.left} onClick={changeStyle}>
            <ArrowBackIosIcon style={{ fontSize: 30 }} />
          </div>
          <div className={s.center} style={{ textAlign: "left" }}>
            <h1 style={{fontSize:'3vw'}}>{images[currImg].title}</h1>
            <p style={{ fontWeight: 100, textShadow: "2px 2px #ff0000" }}>
              {images[currImg].altText}
            </p>
            <p style={{ fontWeight: 100}}>
              {images[currImg].subtitle}
            </p>
            <Button
              style={{ marginLeft: 20, backgroundColor: "#d96227" }}
              variant="contained"
              href={images[currImg].buttonLink}
            >
              {images[currImg].buttonTitle}
            </Button>
          </div>
          <div
            className={s.right}
            onClick={() => {
              currImg < images.length - 1 && setCurrImg(currImg + 1);
            }}
          >
            <ArrowForwardIosIcon style={{ fontSize: 30 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
