
import Shop from '../images/PlasmaSpray2.jpg';
import Shop1 from '../images/FriendlyShop.jpg';
import Shop2 from '../images/Shop2.jpg'

export const images = [
    {title: "AEROSPACE COATINGS",subtitle: "AN FAA CERTIFIED REPAIR STATION", img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/SHOPFLOOR.png", buttonTitle: "Contact Us", buttonLink:"#ContactUs"},
    {title: "ADAVANCED TECHNOLOGY",subtitle: "EQUIPPED WITH ADVANCED TECHNOLOGY TO HANDLE YOUR NEEDS", img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/PlasmaSpray2.jpg", buttonTitle: "SEARCH REPAIRS", buttonLink:"#SEARCH REPAIRS"},
    {title: "WORK FOR US",subtitle: "COME BE PART OF OUR TEAM!", img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/FriendlyShop.jpg", buttonTitle: "APPLY NOW", buttonLink:"https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=54C8762B0621EBE426F6220405D02416"},
    {title: "Shop2",subtitle: "ID Grind2", img: Shop2, buttonTitle: "Contact Us", buttonLink:"#Contact Us"}
]