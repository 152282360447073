
import Button from "@mui/material/Button";
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Grid } from "@mui/material";


function ContactUs() {
    return <div id="ContactUs"
    style={{ backgroundColor: "#195A92", color: "white" }}>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} sm={6} md={6} style={{paddingLeft:20}}> 
            <h1 style={{ fontSize:64, textAlign: 'left', marginBottom: 5, marginTop: 5,padding: 20, paddingBottom:0 }}>Contact Us</h1>
            <Grid container spacing={{ xs: 1, sm: 1, md: 1 }}> 
                <Grid item xs={12} sm={6} md={6} style={{paddingLeft:20}}>
                    <div style={{  color:'white', fontSize:16, padding: 20}}>
                        <p style={{fontSize: 24, margin:0}}>
                            <b>Located At</b>
                        </ p>
                        <p style={{fontSize: 20, margin:0}}>370 Knight Drive</ p>
                        <p style={{fontSize: 20, margin:0}}>Oxford, AL 36203</p>
                        <p style={{fontSize: 24, margin:0}}>
                         (256) 241-2750
                        </p>
                        <br/>
                        <p style={{fontSize: 24, margin:0}}>
                            <b>Mailing Address</b>
                        </ p>
                        < p style={{fontSize: 20, margin:0}}>PO Box 7340</ p>
                        <p style={{fontSize: 20, margin:0}}>Oxford, AL 36203</p>
                        <br/>
                        
                        <p style={{fontSize: 22}}></p>
                        <p style={{fontSize: 24, margin:0}}>
                            <b>Hours</b>
                        </ p>
                        < p style={{fontSize: 20, margin:0}}>Monday-Friday 8AM - 4PM</ p>
                        <p style={{fontSize: 20, margin:0}}>Saturday Closed</p>
                        <p style={{fontSize: 20, margin:0}}>Sunday Closed</p>
                        <br/>
                        </div>
                </Grid>            
                 <Grid item xs={12} sm={6} md={6}>
                        <div style={{ color:'white', fontSize:16, padding: 20}}>
                        <br/>
                        <p style={{fontSize: 24}}><b>Human Resources</b></p>
                        <p><a href='mailto:hr@aerocoatings.com' style={{fontSize: 20}} target="_blank">hr@aerocoatings.com</a></p>
                        <br/>
                        <p style={{fontSize: 24}}><b>Customer Service</b></p>
                        <p><a href='mailto:customerservice@aerocoatings.com' style={{fontSize: 20, margin:0}} target="_blank">customerservice@aerocoatings.com</a></p>
                        <br/>
                        <p style={{fontSize: 24}}><b>Sales</b></p>
                        <p><a href='mailto:sales@aerocoatings.com' style={{fontSize: 20, margin:0}} target="_blank">sales@aerocoatings.com</a></p>
                        
                        <Button
                            style={{
                            float: 'left',
                            backgroundColor: "#d96227",
                            border: "5px, white, solid",
                            marginTop:30
                    }}
                    variant="contained"
                >
                    <a href='https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=54C8762B0621EBE426F6220405D02416'>Job Openings</a>
                </Button>
                        </div>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}> 
                <div  style={{ backgroundColor:"#195A92",  color:'white', fontSize:16}}>
                    <div >
                    <img
                        src="https://aci-website.s3.us-east-2.amazonaws.com/Images/ACI.jpg"
                        alt="Oxford Alabama"
                        
                        width= "90%"
                        />
                        </div>
                    </div>
            </Grid>
        </Grid>
    </div>
}

export default ContactUs