import Header from "./components/Header";
import Footer from "./components/Footer";
import Carousel from "./components/Carousel";
import Documents from "./components/Documents";
import WhoWeAre from "./components/WhoWeAre";
import Location from "./components/Location";
import WorkForUs from "./components/WorkForUs";
import Services from "./components/Services";
import Marketing from "./components/Marketing";
import ContactUs from "./components/ContactUs";
import "./App.css";
import React, { useState } from "react";
import "typeface-roboto";
import { motion, useScroll } from "framer-motion";
import "@fontsource/jost"; // Defaults to weight 400
import "@fontsource/jost/400.css"; // Specify weight
import "@fontsource/jost/400-italic.css"; // Specify weight and style
import { Button } from "@mui/material";
import SearchRepairs from "./components/SearchRepairs";
import HailIcon from "@mui/icons-material/Hail";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";

function Component() {
  const { scrollYProgress } = useScroll();

  return <motion.div style={{ scaleX: scrollYProgress }} />;
}

function App() {
  const helmetContext = {};
  const [style, setStyle] = useState("");
  const queryClient = new QueryClient();

  const changeStyle = () => {
    setStyle("bgFadeIn");
  };

  return (
    <HelmetProvider context={helmetContext}>
      <QueryClientProvider client={queryClient}>
        <div className="App background">
          <div className="container1">
            <header className="App-header">
              <Header />
            </header>
            <Carousel />
            <Location />
            <div style={{ height: "300px" }}></div>
            <WhoWeAre />
            <Marketing />
            <SearchRepairs />
            <Services />
            <Documents />
            <WorkForUs />
            <ContactUs/>
            <div style={{ height: "300px" }}></div>
            <Button
              className="applyButton"
              style={{
                background: "rgb(217, 98, 39)",
                position: "absolute",
                right: 20,
                bottom: 20,
                color: "white",
              }}
            >
              <a href="https://recruiting.adp.com/srccar/public/RTI.home?_fromPublish=true&c=1215401&d=ExternalCareerSite#/"
              target="_blank">
                <HailIcon style={{ maringTop: 10 }} /> Apply Now!
              </a>
            </Button>
            <Footer />
            <div
              className={style}
              onClick={changeStyle}
              style={{
                backgroundColor: "white",
                color: "#195A92",
                padding: 20,
                fontSize: 16,
                textAlign: "right",
              }}
            >
              &#169; Aerospace Coatings International. All rights reserved.{" "}
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
