import http from "./http-common";

export const getERIs = ({ queryKey} ) => {
    console.log('queryKey', queryKey)
  const search = queryKey.length > 1 ? queryKey[1].term : null;


  let url = "/eri/search";
  
  if (search) url += `?term=${search}`;
  console.log('url', url)
  return http.get(url);
};
