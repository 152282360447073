import * as React from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

function WorkForUs() {
  return (
    <div>
      <div id="WorkForUs" style={{ backgroundColor: "#fff", color: "white" }}>
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 2 }}
          style={{ padding: 0, margin: 0 }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            style={{
              textAlign: "left",
              padding: 10,
              paddingBottom: 5,
              margin: 0,
            }}
          >
            <img
              src="https://aci-website.s3.us-east-2.amazonaws.com/Images/JUSTIN.png"
              alt="Employment"
              width="100%"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            style={{ textAlign: "center", padding: 20 }}
          >
            <h1 style={{ color: "#333333", fontSize: 60, marginBottom: 15 }}>
              WANT TO WORK FOR US?
            </h1>
            <p
              style={{
                color: "#333333",
                fontSize: 18,
                textAlign: "center",
                paddingTop: 0,
              }}
            >
              Come be part of an excellent team.{" "}
            </p>
            <Button
              style={{
                backgroundColor: "#d96227",
                border: "5px, white, solid",
              }}
              variant="contained"
            >
              <a href="https://recruiting.adp.com/srccar/public/RTI.home?_fromPublish=true&c=1215401&d=ExternalCareerSite#/" target="_blank">
                APPLY NOW
              </a>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default WorkForUs;
