import http from "./http-common";

export const getPartImage = ({ queryKey }) => {
  console.log('queryKey', queryKey)
  const search = queryKey.length > 1 ? queryKey[1].partNumber : null;
 

  let url = "/image/partimage";
  
  if (!search) {
    return null
  }

  if (search.length > 1){
    console.log("TEST")
  }
  
  url += `?partNumber=${search}`;
  console.log('queryKey', url)
  return http.get(url);
};
