import s from "./Services.module.css";
import { Grid } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import SettingsIcon from "@mui/icons-material/Settings";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";

//<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.845897633167!2d-85.88365852389411!3d33.58335017333709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x888bb705644266a7%3A0xdd03a0dc2effae6c!2s370%20Knight%20Dr%2C%20Oxford%2C%20AL%2036203!5e0!3m2!1sen!2sus!4v1692976604610!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

function Services() {
  return (
    <div id="Services">
      <div
        style={{
          backgroundColor: "#195A92",
          color: "white",
          fontSize: 16,
          padding: 20,
        }}
      >
        <h1 style={{ fontSize: 42 }}>SERVICES</h1>

        <Grid
          container
          spacing={{ xs: 1, sm: 12, md: 12 }}
          columnSpacing={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        >
          <Grid item sm={1} md={1}></Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className={s.card}>
              <EngineeringIcon style={{ fontSize: 50 }} />
              <h3>ENGINEERING</h3>
              <p>Repair Development</p>
              <p>DER Approval</p>
              <p>Nondestructive Material Analysis</p>
              <p>Reverse Engineering</p>
              <p>Technical Documentation</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className={s.card}>
              <ThumbUpOffAltIcon style={{ fontSize: 50 }} />
              <h3>QUALITY ASSURANCE</h3>
              <p>Flourescent Penetrant Inspection</p>
              <p>Magnetic Particle Inspection</p>
              <p>NDT Personnel Qualifications Program</p>
              <p>Nital Etch Inspection</p>
              <p>Rockwell Hardness Testing</p>
              <p>Shotpeen</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className={s.card}>
              <SettingsIcon style={{ fontSize: 50 }} />
              <h3>PLATINGS/COATINGS</h3>
              <p>Chrome Plating</p>
              <p>LHE Cadmium Plating</p>
              <p>Ti-Cad Plating</p>
              <p>Bronze Plating</p>
              <p>Copper Plating</p>
              <p>Acid Cadmium Plating</p>
              <p>Zinc Phosphate Plating</p>
              <p>Manganese Phosphate Plating</p>
              <p>Sulfamate Nickel Plating</p>
              <p>Silver Plating</p>
              <p>Black Oxide</p>
              <p>HYSOL</p>
              <p>Dry Film Lubrication</p>
              <p>Plasma/HVOF Spray</p>
              <p>Chromate Conversion</p>
              <p>Electroless Nickel</p>
              <p>Welding</p>
              <p>Passivation</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className={s.card}>
              <ScatterPlotIcon style={{ fontSize: 50 }} />
              <h3>BLAST MEDIA</h3>
              <p>Steel Shot</p>
              <p>Glass Bead</p>
              <p>Sand Blast</p>
              <p>Rubber Bead Blast</p>
              <p>Pulverized Walnut Shells</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <div className={s.card}>
              <PrecisionManufacturingIcon style={{ fontSize: 50 }} />
              <h3>GRINDING/MACHINING</h3>
              <p>Cylindrical Grinding</p>
              <p>Internal Grinding</p>
              <p>Jig Grinding</p>
              <p>Honing</p>
              <p>EDM</p>
              <p>Full Machine Shop Capabilties CNC/Conventional</p>
              <p>Superfinish</p>
            </div>
          </Grid>
          <Grid item sm={1} md={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Services;
