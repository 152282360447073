import s from "./Documents.module.css";
import * as React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

function Documents() {
  return (
    <div
      id="DOCUMENTS"
      className={s.documentsHeader}
      style={{ backgroundColor: "#777", color: "white", paddingTop: 25 }}
    >
      <Helmet>
        <title>Aerospace Coatings International</title>
        <meta
          name="description"
          content="Beginner friendly page for learning React Helmet."
        />
      </Helmet>
      <div style={{ fontSize: 16 }}>
        <Grid
          container
          style={{ paddingTop: 0 }}
          spacing={{ xs: 1, sm: 1, md: 3 }}
          columnSpacing={{ xs: 1.5, sm: 1.5, md: 1.5 }}
        >
          <Grid item sm={2} md={2}></Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={s.card}>
              <img
                src="https://aci-website.s3.us-east-2.amazonaws.com/Images/Matt.jpg"
                alt="FAA"
                style={{ width: "100%" }}
              />
              <h4>QUALITY DOCUMENTS</h4>
              <div className={s.container}>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_Certification.pdf"
                    target="_blank"
                  >
                    FAA Certification
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_Operations_Specifications.pdf"
                    target="_blank"
                  >
                    FAA Operation Specification
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_CapabilityList.pdf"
                    target="_blank"
                  >
                    FAA Capability List Manual
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_DRSQM.pdf"
                    target="_blank"
                  >
                    FAA DRSQM
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/SMSVP.pdf"
                    target="_blank"
                  >
                    FAA SMSVP Repair Station
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/EASA_Certification.pdf"
                    target="_blank"
                  >
                    EASA Certification 
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/EASA_Supplement.pdf"
                    target="_blank"
                  >
                    EASA Supplement 
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_Certification.pdf"
                    target="_blank"
                  >
                    CAAC Certification
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_CAPABILITY.pdf"
                    target="_blank"
                  >
                    CAAC Capability List Manual
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_MAINTENANCE_MANUAL.pdf"
                    target="_blank"
                  >
                    CAAC Maintenance Orginization Manual
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/UK_CAA_Certificate.pdf"
                    target="_blank"
                  >
                    UK CAA Certification
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/UK_CAA_SUPPLEMENT.pdf"
                    target="_blank"
                  >
                    UK CAA SUPPLEMENT
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/AS9110C.pdf"
                    target="_blank"
                  >
                    AS9110C Certification
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/QMS_Manual.pdf"
                    target="_blank"
                  >
                    Quality Management System Manual
                  </a>
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <div className={s.card}>
              <img
                src="https://aci-website.s3.us-east-2.amazonaws.com/Images/PlasmaSpray.jpg"
                alt="Plasma Spray"
                style={{ width: "100%" }}
              />
              <h4>CUSTOMER SERVICE FORMS</h4>
              <div className={s.container}>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/ACI_CreditApp2011.pdf"
                    target="_blank"
                  >
                    Credit Application
                  </a>
                </p>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <div className={s.card}>
              <img
                src="https://aci-website.s3.us-east-2.amazonaws.com/Images/Exchanges.jpg"
                alt="EASA"
                style={{ width: "100%" }}
              />
              <h4>OTHER</h4>
              <div className={s.container}>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/Purchase_Order_Terms_and_Conditions.pdf"
                    target="_blank"
                  >
                    Purchase Order Terms and Conditions
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/Exchange_Clause.pdf"
                    target="_blank"
                  >
                    Exchange Clause
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/WarrantyPolicy.pdf"
                    target="_blank"
                  >
                    Warranty Policy
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/TermsAndConditions.pdf"
                    target="_blank"
                  >
                    ACI Terms and Conditions
                  </a>
                </p>
              </div>
            </div>
          </Grid>
          <Grid item sm={2} md={2}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Documents;
