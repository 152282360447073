import * as React from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import s from "./WhoWeAre.module.css";

let opening =
  "Our extensive in-house capabilities allow us to offer a true one-stop-shop experience for our airline, OEM, MRO and military customers.";
let subtext =
  "Our level of control over the repair process lends us the uniquely independent opportunity to pass along direct savings to our customers without sacrificing quality or turn time, or having to manage a cumbersome supplier base.";
let paragraph3 =
  "As a certified repair station, we hold FAA, EASA, CAAC, ISO9001:2015, and AS9110C certifications.";
let paragraph4 =
  "With in-house engineering services available and an existing portfolio of over 2,200 FAA approved or authorized repairs, we have the ability to extend the value of nearly every part we service which considerably lowers the operating cost for our customers.";

function WhoWeAre() {
  return (
    <div>
      <div
        id="WhoWeAre"
        className={s.whoWeAreHeader}
        style={{ backgroundColor: "#195A92", color: "white", padding: 40 }}
      >
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <div
              id="Opening"
              style={{ padding: 20, fontSize: 22, textAlign: "left" }}
            >
              <h1 style={{ marginTop: 20, marginBottom: 0, paddingTop: 0 }}>
                OUR CAPABILITIES
              </h1>
              {opening} <br />
              <br />
              {subtext}
              <br />
              <br />
              <Button
                style={{
                  backgroundColor: "#d96227",
                  border: "5px, white, solid",
                }}
                variant="contained"
              >
                <a href="#SEARCH REPAIRS">SEARCH CAPABILTIES</a>
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <img
              src="https://aci-website.s3.us-east-2.amazonaws.com/Images/ContactUs.jpg"
              alt="Capabilities"
              width="100%"
            />
          </Grid>
        </Grid>{" "}
      </div>

      <div
        id="WhoWeAre2"
        style={{ backgroundColor: "#777", color: "white", padding: 40 }}
      >
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <img
              src="https://aci-website.s3.us-east-2.amazonaws.com/Images/GrindShop.jpg"
              alt="FAA Repair Station"
              width="100%"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <div
              id="Opening"
              style={{ padding: 20, fontSize: 22, textAlign: "left" }}
            >
              <h1 style={{ marginTop: 20, marginBottom: 0, paddingTop: 0 }}>
                CERTIFIED REPAIR STATION
              </h1>
              {paragraph3}
              <br />
              <br />
              {paragraph4} <br />
              <br />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default WhoWeAre;
