import s from "./Footer.module.css";
import { Grid } from "@mui/material";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <div className={s.footer}>
      <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} sm={12} md={3}>
          <p>
            <b>Located At</b>
          </p>
          <p>370 Knight Drive</p>
          <p>Oxford, AL 36203</p>
          <p>hr@aerocoatings.com</p>
          <p>
            <b>O:</b> 256.241.2750
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <p>
            <b>Company</b>
          </p>
          <p>
            <a href="#WhoWeAre">About</a>
          </p>
          <p><a href="https://recruiting.adp.com/srccar/public/RTI.home?_fromPublish=true&c=1215401&d=ExternalCareerSite#/" target="_blank">Job Openings</a></p>
          <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CookiePolicy.pdf"
                    target="_blank"
                  >
                    Cookie Policy
                  </a>
                </p>
                <p>
                  <a
                    className={(s.anchor, s.link)}
                    href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/PrivacyPolicy.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <p>
            <b>Careers</b>
          </p>
          <p>
            Come be part of an excellent team at Aerospace Coatings
            International! Be the best by learning from the best.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <p>
            <b>Find Us on Social Media!</b>
          </p>
          <p>
            <a href="http://www.facebook.com/aerocoatings" target="_blank">
              <Facebook />
            </a>{" "}
            <a href="https://www.instagram.com/aerospace_coatings/" target="_blank">
              <Instagram />
            </a>{" "}
            <a href="https://www.linkedin.com/company/aerospace-coatings-international-inc-/mycompany/" target="_blank">
              <LinkedIn />
            </a>
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
