import * as React from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ContactUs from "./ContactUs";
import s from "./Location.module.css";
import { Helmet } from "react-helmet-async";

let paragraph2 =
  "Aerospace Coatings International is an FAA certified repair station specializing in turn-key repair services for component piece parts.";

function Location() {
  return (
    <div id="HOME" className={s.homeHeader}>
      <div
        id="Location"
        style={{ backgroundColor: "#FFFFFF", color: "white", padding: 40 }}
      >
        <Helmet>
        <title>Aerospace Coatings International</title>
        <meta
          name="description"
          content="Aerospace Coatings International is an FAA certified repair station specializing in turn-key repair services for component piece parts."
        />
      </Helmet>
        <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={6} md={6}>
            <img
              src="https://aci-website.s3.us-east-2.amazonaws.com/Images/OxfordBanner.jpg"
              alt="Oxford Alabama"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div
              id="Opening"
              style={{
                color: "#444444",
                padding: 20,
                fontSize: 22,
                textAlign: "left",
              }}
            >
              <h1
                style={{
                  color: "#444444",
                  marginTop: 20,
                  marginBottom: 0,
                  paddingTop: 0,
                }}
              >
                COME SEE US!
              </h1>
              We are conveniently located in{" "}
              <a
                style={{ color: "#195A92" }}
                href="https://www.google.com/maps/place/370+Knight+Dr,+Oxford,+AL+36203/@33.5833502,-85.8810836,17z/data=!3m1!4b1!4m6!3m5!1s0x888bb705644266a7:0xdd03a0dc2effae6c!8m2!3d33.5833502!4d-85.8810836!16s%2Fg%2F11c1cfh_fj?entry=ttu"
                target="_blank"
              >
                Oxford, Alabama
              </a>
              , where we are able to provide world wide support to the aerospace
              industry. <br />
              <br />
              {paragraph2} <br />
              <br />
              <Button
                
                style={{
                  backgroundColor: "rgb(217,98,39)",
                  border: "5px, white, solid",
                }}
                variant="contained"
              >
                <a href="#ContactUs">CONTACT US</a>
              </Button>
            </div>
          </Grid>
        </Grid>{" "}
      </div>
    </div>
  );
}

export default Location;
